import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  FormControl,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  TableRow,
  Autocomplete,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CommentIcon from '@mui/icons-material/Comment';
import MailIcon from '@mui/icons-material/Mail';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import _, { isArray } from 'lodash';

import { taskList } from '../../redux/services/hiring-workflow.service';
import { ROOT_URL } from '../../utils/constants';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { updateCandidateStage } from '../../redux/services/fetch-candidates';
import { candidateById } from '../../redux/features/applications/applicationSlice';

interface Props {
  onTaskSubmit: any;
  onTaskTypeSubmit: any;
  loading: boolean;
  applicationId: number;
  jobId: number;
  taskTLFeedback: any;
  taskCLFeedback: any;
  onOpenEventModal?: any;
  stakeholders: Array<any>;
  hiringWorkflow: Array<any>;
}

const inteviewTaskGroup = [
  'CANDIDATE_PHONE_CONTACTED',
  'ASSIGNMENT_SUBMITTED',
  'CALENDAR_INVITE_SHARED',
  'CALENDAR_INVITE_ACK',
  'CLIENT_SHARED_CALENDAR_INVITE',
  'F2F_COMPLETED',
  'SELECT_FOR_INTERVIEW',
  'RESCHEDULED',
  'CANDIDATE_NOT_TURN_UP',
  'ONLINE_INTERVIEW_DONE',
  'CANDIDATE_SELECTED',
];

const afterSelectionTaskGroup = [
  'OFFER_EXTENDED',
  'OFFER_ACCEPTED',
  'OFFER_DECLINED',
  'NEGOTIATION_PROGRESS',
  'CANDIDATE_RESIGNED',
  'REGIGNATION_ACCEPTED',
  'CANDIDATE_JOINED',
  'CANDIDATE_BACKOUT',
  'CANDIDATE_REGINED_BFR_BILLING',
  'CANDIDATE_REGINED_BFR_BILLING',
  'CANDIDATE_FIRED_BFR_BILLING',
  'CANDIDATE_COMPLETED_BFR_BILLING',
];

const groupTask = (taskList: Array<any>, group: Array<string>) => {
  return taskList.filter((task) => group.includes(task.slug));
};

export default (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [TLFeedback, setTLFeedback] = useState<string>('');
  const [CLFeedback, setCLFeedback] = useState<string>('');
  const [tasks, setTaskList] = useState<Array<any>>([]);
  const [afterSelectionTask, setAfterSelectionTask] = useState<Array<any>>([]);
  const [stackholderOptions, setStakehoderOptions] = useState<any>([]);
  const [selectedStackholderToShareReview, setStackeholderToReview] =
    useState<any>([]);

  const { candidate } = useAppSelector((state) => state.application);
  const { hiringWorkflow } = useAppSelector((state) => state.hiringWorkflow);
  const dispatch = useAppDispatch();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareClick = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleInterviewGroupClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
  };

  useEffect(() => {
    taskList(props.applicationId)
      .then(({ data }: any) => {
        const _afterSelectionTask = groupTask(data, afterSelectionTaskGroup);
        const _hiringTask = groupTask(data, inteviewTaskGroup);
        setAfterSelectionTask(_afterSelectionTask);
        setTaskList(_hiringTask);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.applicationId, props.loading]);

  const handleSelectTask = (
    taskType: string,
    assignedToIds?: Array<number>
  ) => {
    handleClose();
    props.onTaskTypeSubmit(taskType, assignedToIds);
  };

  useEffect(() => {
    const stakeholderOptions = props.stakeholders.map((stakeholder) => ({
      label: `${stakeholder.name}(${stakeholder.email})`,
      value: stakeholder.id,
    }));
    setStakehoderOptions(stakeholderOptions);
  }, [props.stakeholders]);

  const handleStakeholderSelect = (e: any, value: any) => {
    setStackeholderToReview(value);
  };

  const handleStageChange = (event: SelectChangeEvent) => {
    //@ts-ignore
    updateCandidateStage(candidate.details.id, event.target.value)
      .then((res) => {
        dispatch(candidateById(Number(candidate.details.id)));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const selectedStage: any = candidate?.details?.workFlowId || 0;
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Recruiter Actions
        </Typography>
        <Stack
          direction="row"
          gap={2}
          mt={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Tooltip title="Send the job description to the candidate">
            <IconButton
              color="primary"
              onClick={() => handleSelectTask('SHARE_JD')}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
          <Box>
            <Tooltip title="Share Profile">
              <IconButton color="primary" onClick={handleShareClick}>
                <ShareIcon />
              </IconButton>
            </Tooltip>

            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleClose}
            >
              <Stack direction="row" gap={1} p={2}>
                <Autocomplete
                  multiple
                  sx={{
                    minWidth: 400,
                  }}
                  options={stackholderOptions}
                  filterSelectedOptions
                  onChange={handleStakeholderSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Reviewer"
                      placeholder="Select Reviewer"
                    />
                  )}
                />
                <Button
                  variant="contained"
                  disabled={_.isEmpty(selectedStackholderToShareReview)}
                  onClick={() => {
                    const stakeholderIds = selectedStackholderToShareReview.map(
                      (stakeholder: any) => stakeholder.value
                    );
                    handleSelectTask('PROFILE_SHARED_TO_TL', stakeholderIds);
                  }}
                >
                  Share for Review
                </Button>
              </Stack>
              {/* <MenuItem
                onClick={() => handleSelectTask("PROFILE_SHARED_TO_TL")}
              >
                Share Profile to Team Lead
              </MenuItem>
              <MenuItem
                onClick={() => handleSelectTask("PROFILE_SHARED_TO_CLIENT")}
              >
                Share Profile to Client
              </MenuItem> */}
            </Menu>
          </Box>
          <Tooltip title="Create Event">
            <IconButton color="primary" onClick={props.onOpenEventModal}>
              <CalendarMonthIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Comment">
            <IconButton
              color="primary"
              onClick={props.onOpenEventModal}
              disabled
            >
              <CommentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share Assignment">
            <IconButton color="primary" onClick={props.onOpenEventModal}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Mail">
            <IconButton
              color="primary"
              onClick={props.onOpenEventModal}
              disabled
            >
              <MailIcon />
            </IconButton>
          </Tooltip>
          {tasks.length > 0 && (
            <Box>
              <Tooltip title="Interview Actions">
                <IconButton color="primary" onClick={handleInterviewGroupClick}>
                  <Diversity3Icon />
                </IconButton>
              </Tooltip>

              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleClose}
              >
                {tasks.map((task, i) => (
                  <MenuItem
                    value={task.id}
                    key={`task-${task.id}`}
                    onClick={() => handleSelectTask(task.slug)}
                  >
                    {task.task}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {afterSelectionTask.length > 0 && (
            <Box>
              <Tooltip title="After Selection">
                <IconButton color="primary" onClick={handleClick}>
                  <HowToRegIcon />
                </IconButton>
              </Tooltip>

              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {afterSelectionTask.map((task, i) => (
                  <MenuItem
                    value={task.id}
                    key={`task-${task.id}`}
                    onClick={() => handleSelectTask(task.slug)}
                  >
                    {task.task}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {isArray(hiringWorkflow.data) && (
            <FormControl sx={{ minWidth: '200px' }} size="small">
              <InputLabel id="id-action-change-stage">Change Stage </InputLabel>
              <Select
                labelId="id-action-change-stage"
                size="small"
                label="Change Stage"
                value={selectedStage}
                onChange={handleStageChange}
              >
                {hiringWorkflow.data.map((stage: any) => (
                  <MenuItem key={`hiring-stage-${stage.id}`} value={stage.id}>
                    {stage.stageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
        <Stack
          direction="column"
          mt={2}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {props.taskTLFeedback && props?.taskTLFeedback.length > 0 && (
            <Stack direction="column" mb={2}>
              <FormLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Feedback you received from the team lead?
                <Tooltip title="Choose Feedback you recieved">
                  <InfoOutlinedIcon fontSize="inherit" />
                </Tooltip>
              </FormLabel>
              <FormControl>
                <RadioGroup
                  row
                  value={TLFeedback}
                  onChange={(e) => {
                    setTLFeedback(e.target.value);
                  }}
                >
                  {props.taskTLFeedback.map((action: any) => (
                    <FormControlLabel
                      value={action.id}
                      key={`rec-actions-${action.id}`}
                      control={<Radio />}
                      label={action.task}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                sx={{ width: 64, padding: 0, margin: 0 }}
                onClick={() => props.onTaskSubmit({ taskId: TLFeedback })}
              >
                Submit
              </Button>
            </Stack>
          )}
          {props.taskCLFeedback && props?.taskCLFeedback.length > 0 && (
            <Stack direction="column" gap={1}>
              <FormLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Feedback you received from the client?
                <Tooltip title="Choose Feedback you recieved">
                  <InfoOutlinedIcon fontSize="inherit" />
                </Tooltip>
              </FormLabel>
              <FormControl>
                <RadioGroup
                  row
                  value={CLFeedback}
                  onChange={(e) => {
                    setCLFeedback(e.target.value);
                  }}
                >
                  {props.taskCLFeedback.map((actions: any) => (
                    <FormControlLabel
                      key={`rec-actions-${actions.id}`}
                      value={actions.id}
                      control={<Radio />}
                      label={actions.task}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                sx={{ width: 64, padding: 0, margin: 0 }}
                onClick={() => props.onTaskSubmit({ taskId: CLFeedback })}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
