import {
  Card,
  CardContent,
  Typography,
  AvatarGroup,
  Avatar,
} from '@mui/material';
import dayjs from 'dayjs';
import ReativeTime from 'dayjs/plugin/relativeTime';
import { stringAvatar } from '../../utils/helper-functions';

dayjs.extend(ReativeTime);

interface Props {
  event: any;
}

export default (props: Props) => {
  const { event } = props;
  return (
    <Card
      sx={{
        minWidth: 250,
        width: '100%',
      }}
    >
      <CardContent>
        <Typography variant="body2" fontWeight="bold">
          {event.task.task}
        </Typography>
        <Typography variant="body2">{event.description}</Typography>
        <Typography variant="caption">
          {dayjs(event.createdAt).fromNow()}
        </Typography>
        {event.recruiter.name && (
          <AvatarGroup>
            <Avatar
              alt={event.recruiter.name}
              sx={{ width: 20, height: 20, bgcolor: '#ad51db', fontSize: 10 }}
              {...stringAvatar(event.recruiter.name)}
            ></Avatar>
          </AvatarGroup>
        )}
      </CardContent>
    </Card>
  );
};
