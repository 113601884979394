import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Hidden, Typography, Link } from '@mui/material';

import { drawerWidth } from '../../../utils/constants';
import MenuList from './menu-list';

import DownloadAppCTA from '../../download-app-CTA';

const Sidebar = ({ drawerOpen, drawerToggle }: any) => {
  const theme = useTheme();
  //@ts-ignore
  const isElectron = window && window.process && window.process.type;

  const drawer = (
    <>
      <Box
        sx={{
          display: 'flex',
          px: 2,
          pt: '10px',
          pb: '15px',
          mx: 'auto',
          alignItems: 'center',
        }}
      >
        <Link href="/#/" underline="none" data-component-name="DrawerLogo">
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Montserrat',
              color: '#ff6451',
            }}
          >
            HireXL
          </Typography>
        </Link>
      </Box>
      <Hidden mdUp>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </Hidden>
      {!isElectron && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">Try Our Desktop App</Typography>
          <DownloadAppCTA />
        </Box>
      )}
    </>
  );

  return (
    <Box component="nav" sx={{ flexShrink: 0 }}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={drawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={drawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              borderRight: 'none',
              overflowY: 'hidden',
              '&:hover': {
                overflowY: 'auto',
              },
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
