import { Divider, List, Typography } from '@mui/material';

import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

interface Props {
  item: {
    title?: string;
    caption?: string;
    children?: any;
  };
}

const NavGroup = ({ item }: Props) => {
  const items = item.children?.map((menu: any) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="subtitle2" display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="body2" display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

export default NavGroup;
