import {
  TextField,
  Autocomplete as MUIAutocomplete,
  createFilterOptions,
  Chip,
} from '@mui/material';

const filter = createFilterOptions();

const Autocompelete = (props: any) => {
  const {
    label = 'Type...',
    placehoder = '',
    value,
    options,
    onChange,
    name,
    error,
    onBlur,
    multiple = true,
    ...componentProps
  } = props;
  return (
    <MUIAutocomplete
      multiple={multiple}
      value={value}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      id={name}
      {...componentProps}
      limitTags={3}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onBlur={onBlur}
      options={options}
      placeholder={placehoder}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        filtered.push(inputValue);

        return filtered;
      }}
      sx={{
        '.MuiChip-label': {
          textTransform: 'lowercase',
        },
        minWidth: '250px',
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} size="small" error={error} />
      )}
    />
  );
};

export default Autocompelete;
