import { axiosInstance } from '../../utils/axios.instance';

export default class JobApplicationAPI {
  static async rejectCandidate(
    id: number,
    rejected: 'Y' | 'N',
    rejectionReason?: string
  ) {
    return axiosInstance({
      url: '/api/v1/job-application/reject',
      method: 'PUT',
      data: {
        id,
        rejected,
        rejectionReason,
      },
    });
  }

  static async fetchCandidate(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/job-application/candidate-info/${id}`,
    });
  }

  static async fetchJobSnapshot(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/job-application/snapshot/${id}`,
    });
  }

  static async assignFromTalentPool(
    jobs: Array<number | undefined>,
    candidateId: Array<number>
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/assign-from-talent-pool',
      data: {
        candidate: candidateId,
        jobs,
      },
    });
  }

  static async updateApplicant(formData: any) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/job-application/applicant',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }
}
