import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography, Link } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { setUploadType } from '../../redux/features/applications/uploadApplicantSlice';

import AddSingleCandidate from '../AddSingleCandidate';
import AddMultipleCandidate from '../AddMultipleCandidate';
import UploadFiles from '../upload-files';

const AddCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { permissions } = useAppSelector((state) => state.app);
  const { selectedJob, uploadType } = useAppSelector(
    (state) => state.uploadApplicant
  );

  useEffect(() => {
    const { add_candidate = '' } = permissions;
    if (!add_candidate) {
      navigate('/postings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const queryType = searchParams.get('type');

  useEffect(() => {
    if (queryType) {
      dispatch(setUploadType(queryType));
    }
  }, [queryType]);

  return (
    <Box>
      <Grid container p={2} spacing={2}>
        <Grid item xs={12} md={9}>
          {uploadType === 'single' && <AddSingleCandidate job={selectedJob} />}
          {uploadType === 'multiple' && (
            <AddMultipleCandidate job={selectedJob} />
          )}
          {uploadType === 'all' && <UploadFiles job={selectedJob} />}
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={1} alignItems="flex-start">
            <Typography variant="caption">Other Options</Typography>
            {uploadType != 'single' && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  dispatch(setUploadType('single'));
                }}
                underline="none"
              >
                Upload single candidate
              </Link>
            )}
            {uploadType != 'multiple' && selectedJob.id && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  dispatch(setUploadType('multiple'));
                }}
                underline="none"
              >
                Upload zip/CSV file
              </Link>
            )}
            {uploadType != 'all' && selectedJob.id && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  dispatch(setUploadType('all'));
                }}
                underline="none"
              >
                Multiple file(s) upload
              </Link>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCandidate;
