import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Box, Typography } from '@mui/material';

import Task from './Task.react';

interface Props {
  timeline: any;
}

export default (props: Props) => {
  return (
    <Box>
      <Typography variant="h5">Timeline</Typography>
      <Timeline sx={{ alignItems: 'flex-start' }}>
        {props.timeline.map((event: any, i: number) => (
          <TimelineItem key={`timeline-item-${i}`}>
            <TimelineSeparator>
              <TimelineDot color="secondary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Task event={event} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};
