import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const settings = {
  id: 'settings',
  title: 'Settings',
  type: 'group',
  children: [
    // {
    //   id: 'settings',
    //   title: 'Settings',
    //   type: 'item',
    //   url: '/settings',
    //   icon: SettingsIcon,
    //   breadcrumbs: false,
    // },
    {
      id: 'calendar',
      title: 'Calendar',
      type: 'item',
      url: '/calendar',
      icon: CalendarMonthIcon,
      breadcrumbs: false,
    },
  ],
};

export default settings;
