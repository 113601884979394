import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Typography,
  Stack,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import randomString from 'randomstring';
import io from 'socket.io-client';
import GoogleIcon from '@mui/icons-material/Google';

import palette from '../../theme/palette';

import AddEventModal from './AddEventModal.react';
import CalendarAPI from '../../redux/services/calendar.service';
import { ROOT_URL } from '../../utils/constants';
import dayjs from 'dayjs';
import { IEvent } from '../../types/calendar';
import { truncate } from 'fs';

const socket = io(ROOT_URL);

const renderEventContent = (eventInfo: any) => {
  return (
    <Stack direction="column" sx={{ backgroundColor: palette.primary.main }}>
      <Typography variant="body2" color="#FFFFFF">
        {eventInfo.event.title}
      </Typography>
      <Typography variant="caption" color="#FFFFFF">
        {eventInfo.timeText}
      </Typography>
    </Stack>
  );
};

const EventCalendar = () => {
  const calendarRef = useRef(null);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [isEventSubmitting, setEventSubmitting] = useState<boolean>(false);
  const [calendarEvents, setCalendarEvents] = useState<Array<any>>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [connectedAccount, setConnectedAccount] = useState<string>('');
  const [syncEvents, setSyncEvent] = useState<boolean>(false);

  // Service Method needs to move to redux
  const callConnectedAccount = () => {
    CalendarAPI.getConnectedAccount()
      .then((response) => {
        setConnectedAccount(response?.data?.email || '');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listEvents = () => {
    CalendarAPI.listEvents()
      .then(({ data }) => {
        const events = data.map((event: any) => ({
          title: event.title,
          date: event.start,
          eventId: event.id,
          raw: event,
        }));
        setCalendarEvents(events);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const deleteEvent = (selectedEvent: any) => {
    const event = selectedEvent.extendedProps.raw;
    CalendarAPI.deleteEvent(event.id, event.googleEventId)
      .then((res) => {
        selectedEvent.remove();
        listEvents();
        setOpenEventModal(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    callConnectedAccount();
    listEvents();

    socket.on('connect', () => {
      const token = localStorage.getItem('authToken');
      socket.emit('user', token);
    });

    socket.on('google-auth', (data) => {
      setConnectedAccount(data.email);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleEventClick = (clickInfo: any) => {
    setSelectedEvent(clickInfo.event);
    setOpenEventModal(true);
  };

  const onEventSet = (event: any) => {
    console.log('event', event);
  };

  const onSelect = (selectInfo: any) => {
    console.log('selectInfo', selectInfo);
  };

  const handleClose = () => {
    setOpenEventModal(false);
  };

  const handleAddEventClick = () => {
    setSelectedEvent({});
    setOpenEventModal(true);
  };

  const handleAddEvent = (event: any) => {
    setEventSubmitting(true);
    const eventRequest: IEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        ).toISOString(),
        timeZone: 'utc',
      },
      end: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        )
          .add(event.duration, 'minutes')
          .toISOString(),
        timeZone: 'utc',
      },
      attendees: event.attendees.map((user: any) => ({ email: user })),
    };
    if (event.googleConfrence) {
      eventRequest['conferenceData'] = {
        createRequest: {
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
          requestId: randomString.generate(10),
        },
      };
    }

    CalendarAPI.addEvent(eventRequest)
      .then((response) => {
        setEventSubmitting(false);
        setOpenEventModal(false);

        listEvents();
        //@ts-ignore
        // let calendarApi = calendarRef?.current?.getApi();
        // setCalendarEvents((prev: any) => {
        //   return [
        //     ...prev,
        //     {
        //       title: event.title,
        //       date: event.date,
        //       raw: event,
        //     },
        //   ];
        // });
        // calendarApi.addEvent({
        //   title: event.title,
        //   start: event.date,
        //   allDay: true,
        // });
      })
      .catch((error) => {
        console.log('err', error);
        setEventSubmitting(false);
      });
  };

  const handleConnectGoogleAccount = () => {
    const token = localStorage.getItem('authToken');
    window.open(`${ROOT_URL}/api/v1/calendar/google-auth?token=${token}`);
  };

  const handleEventDelete = () => {
    deleteEvent(selectedEvent);
  };

  const handleEventEdit = (event: any) => {
    setEventSubmitting(true);
    const eventRequest: IEvent & { id: number; googleEventId?: string | null } =
      {
        id: event.id,
        summary: event.title,
        googleEventId: event.googleEventId,
        description: event.description,
        start: {
          dateTime: dayjs(
            `${event.date} ${event.startTime}`,
            'YYYY-MM-DD hh:mm:A'
          ).toISOString(),
          timeZone: 'utc',
        },
        end: {
          dateTime: dayjs(
            `${event.date} ${event.startTime}`,
            'YYYY-MM-DD hh:mm:A'
          )
            .add(event.duration, 'minutes')
            .toISOString(),
          timeZone: 'utc',
        },
        attendees: event.attendees.map((user: any) => ({ email: user })),
      };
    if (event.googleConfrence) {
      eventRequest['conferenceData'] = {
        createRequest: {
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
          requestId: randomString.generate(10),
        },
      };
    }

    CalendarAPI.updateEvent(eventRequest)
      .then((response) => {
        console.log('response', response);
        setOpenEventModal(false);
        listEvents();
        setEventSubmitting(false);
      })
      .catch((error) => {
        console.log('error', error);
        setOpenEventModal(false);
        setEventSubmitting(false);
      });
  };

  const handleRemoveAccount = () => {
    CalendarAPI.deleteConnectedAccount()
      .then((res) => {
        setConnectedAccount('');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSyncEvents = () => {
    setSyncEvent(true);
    CalendarAPI.syncGoogleEvents()
      .then((response) => {
        listEvents();
        setSyncEvent(false);
      })
      .catch((error) => {
        console.log('error', error);
        setSyncEvent(false);
      });
  };

  return (
    <div data-component-name="Calendar">
      {connectedAccount ? (
        <Stack
          direction="row"
          gap={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            variant="outlined"
            sx={{
              marginY: 2,
            }}
            startIcon={<GoogleIcon />}
            onClick={handleRemoveAccount}
          >
            {`${connectedAccount} Connected `}
          </Button>
          <Tooltip title="Sync Events">
            <IconButton
              color="primary"
              onClick={handleSyncEvents}
              disabled={syncEvents}
            >
              {syncEvents ? <CircularProgress size={20} /> : <SyncIcon />}
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Button
          variant="outlined"
          sx={{
            marginY: 2,
          }}
          startIcon={<GoogleIcon />}
          onClick={handleConnectGoogleAccount}
        >
          Connect a Google Account
        </Button>
      )}

      <AddEventModal
        open={openEventModal}
        handleClose={handleClose}
        onAddEvent={handleAddEvent}
        event={selectedEvent}
        onClickDelete={handleEventDelete}
        onClickEdit={handleEventEdit}
        isLoading={isEventSubmitting}
      />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay, addEventButton',
        }}
        customButtons={{
          addEventButton: {
            text: 'Add Event',
            click: handleAddEventClick,
          },
        }}
        weekends
        editable
        selectMirror
        dayMaxEvents
        eventsSet={onEventSet}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        events={calendarEvents}
        select={onSelect}
      />
    </div>
  );
};

export default EventCalendar;
