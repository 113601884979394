import { useState, useEffect, useRef, MouseEvent } from 'react';
import {
  Box,
  Popper,
  ClickAwayListener,
  CircularProgress,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  ListItem,
  IconButton,
  Modal,
} from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { useTheme } from '@mui/material/styles';

import Transitions from '../../../../theme/extended-components/Transitions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getClients,
  selectClient,
  IClient,
  getUserWorkspaceClients,
} from '../../../../redux/features/dashboard/clientSlice';
import { getJobsByPage } from '../../../../redux/features/jobs/jobSlice';
import { jobStackholders } from '../../../../redux/features/jobs/jobPosting';
import { ChannelInfo } from '../../../workspace-channel';

const ClientSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openChannelInfo, setOpenChannelInfo] = useState<boolean>(false);
  const [clientInfoToShow, setClientInfoToShow] = useState({});

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const dispatch = useAppDispatch();

  //@ts-ignore
  const { selectedClient, loading } = useAppSelector((state) => state.client);
  const { slug = '' }: any = useAppSelector((state) => state.app.workspace);
  const clients = useAppSelector(getUserWorkspaceClients);
  const handleClose = (event: any) => {
    //@ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (slug) {
      dispatch(getClients());
    }
  }, [slug, getClients]);

  useEffect(() => {
    if (selectedClient?.id && slug) {
      dispatch(getJobsByPage({ page: 1 }));
      dispatch(jobStackholders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient?.id, slug]);

  const handleListItemClick = (
    event: MouseEvent<HTMLDivElement>,
    client: IClient
  ) => {
    if (client.id) {
      dispatch(selectClient(client));
    } else {
      dispatch(selectClient({}));
    }

    setSelectedIndex(client.id);
    handleClose(event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (!selectedClient?.name && clients.length) {
      dispatch(selectClient(clients[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient?.name, clients.length]);

  const clientName = selectedClient?.name ? selectedClient.name : '';

  const handleChannelInfoClose = () => {
    setOpenChannelInfo(false);
  };

  const handleChannelOpen = (client: any) => {
    setClientInfoToShow(client);
    setOpenChannelInfo(true);
  };

  return (
    <>
      <Modal
        open={openChannelInfo}
        onClose={() => {}}
        style={{ overflow: 'scroll' }}
      >
        <ChannelInfo
          onClose={handleChannelInfoClose}
          client={clientInfoToShow}
        />
      </Modal>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          width: 'auto',
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {loading === 'pending' ? (
          <CircularProgress size={25} />
        ) : (
          <Button startIcon={<CorporateFareIcon />} variant="text">
            {clientName}
          </Button>
        )}
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? 'top' : 'top-right'}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  sx={{
                    overflow: 'scroll',
                    minHeight: '10vh',
                    maxHeight: '60vh',
                  }}
                >
                  <CardContent style={{ padding: 1 }}>
                    <Box sx={{ p: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 200,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%',
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5,
                          },
                        }}
                      >
                        {clients.map((client) => (
                          <ListItem
                            disablePadding
                            key={`client-${client.id}`}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="comments-button"
                                color="primary"
                                onClick={() => handleChannelOpen(client)}
                              >
                                <PreviewOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemButton
                              sx={{
                                borderRadius: '14px',
                              }}
                              selected={selectedIndex === client.id}
                              onClick={(event) =>
                                handleListItemClick(event, client)
                              }
                            >
                              <ListItemIcon>
                                <CorporateFareIcon fontSize="inherit" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    {client.name}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ClientSection;
