import DashboardIcon from '@mui/icons-material/Dashboard';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  access: 'internal',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'recruiter-report',
      title: 'Reports',
      type: 'item',
      url: '/dashboard/recruiter-report',
      icon: BackupTableIcon,
      breadcrumbs: false,
    },
    {
      id: 'recent-activities',
      title: 'Activities',
      type: 'item',
      url: '/recent-activities',
      icon: FeedOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
