import { useState, useEffect, ChangeEvent, useCallback, useRef } from 'react';
import {
  TextField,
  Stack,
  Card,
  CardContent,
  Typography,
  FormControl,
  Button,
  Chip,
  Box,
  IconButton,
  FormHelperText,
  Autocomplete as MUIAutocomplete,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import _, { isObject } from 'lodash';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import ReativeTime from 'dayjs/plugin/relativeTime';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { validateEmail, generateTable } from '../../utils/helper-functions';
import Autocompelete from '../form-fields/Autocomplete.react';
import EmailBodyEditor from './EmailBodyEditor';
import { RootState } from '../../redux/store';
import { fetchTemplates } from '../../redux/features/mailer/mailer.slice';
import TemplatePopover from './TemplatePopover';
import FileServiceAPI from '../../redux/services/file.service';
import { jobStackholders } from '../../redux/features/jobs/jobPosting';

dayjs.extend(ReativeTime);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validateOnSubmit = (values: any, setErrors: any) => {
  let error = false;
  if (!values.message) {
    error = true;
    setErrors({
      message: 'Message is required',
    });
  }

  if (values.message.length < 15) {
    error = true;
    setErrors({
      message: 'message should be of atleast 15 characters',
    });
  }

  return error;
};

const EmailComposer = (props: any) => {
  const [enableBcc, setEnableBcc] = useState(false);
  const [enableCC, setEnableCC] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [emailAttachement, setEmailAttachment] = useState<any>([]);

  const dispatch = useAppDispatch();
  const { workspace } = useAppSelector((state) => state.app);
  const { selectedClient = {} } = useAppSelector((state) => state.client);
  const { templates } = useAppSelector((state: RootState) => state.mailer);
  const { stackholders } = useAppSelector((state) => state.jobPosting);

  const {
    attachment = [],
    handleEmailSubmit,
    closeBtn = false,
    handleClose,
    loading = 'idle',
  } = props;

  useEffect(() => {
    //@ts-ignore
    if (selectedClient.id && workspace.id) {
      dispatch(jobStackholders());
    }
    //@ts-ignore
  }, [selectedClient.id, workspace.id]);

  useEffect(() => {
    if (attachment.length) {
      setEmailAttachment(attachment);
    }
  }, [attachment]);

  useEffect(() => {
    dispatch(fetchTemplates({}));
  }, []);

  const toggleBcc = () => {
    setEnableBcc((prevState: any) => !prevState);
  };

  const toggleCC = () => {
    setEnableCC((prevState: any) => !prevState);
  };

  const feedbackTable = attachment
    .filter(
      (application: any) => application.feedback && application.feedback.length
    )
    .map((application: any) =>
      generateTable(application.feedback, application.name)
    )
    .join('');

  const handleChangeSearchText = (value: any) => {
    setSelectedTemplate(value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const fileSizeLimit = 2 * 1024 * 1024; // 2 MB in bytes
      if (file.size > fileSizeLimit) {
        toast.error('File size exceeds the limit of 2 MB!', {
          hideProgressBar: true,
        });
        return;
      }
      const formData: any = new FormData();
      formData.append('file', file);
      formData.append('folder', 'event-attachements');
      FileServiceAPI.uploadFileToS3(formData)
        .then((response) => {
          const { url = '' } = response.data;
          setEmailAttachment([
            ...emailAttachement,
            { name: file.name, s3url: url },
          ]);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const recipientOptions =
    stackholders?.list.map((recruiter) => ({
      label: recruiter.email,
      value: recruiter.email,
    })) || [];

  return (
    <Card
      sx={{
        width: '100%',
        minWidth: '400px',
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            py: 1,
          }}
        >
          Compose new email
        </Typography>
        {closeBtn && (
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 1, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            recipients: [],
            subject: props.subject || '',
            bcc: [],
            cc: [],
            message: '',
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.recipients || values.recipients.length < 1) {
              errors.recipients = 'Recipient is required!';
            }

            if (values.cc.length && !_.every(values.cc, validateEmail)) {
              errors.cc = 'All CC email should be valid email!';
            }

            if (values.bcc.length && !_.every(values.bcc, validateEmail)) {
              errors.bcc = 'All Bcc email should be valid email!';
            }
            if (
              values.recipients.length &&
              !_.every(values.recipients, validateEmail)
            ) {
              errors.recipients = 'All recipient should be valid email!';
            }
            if (!values.subject) {
              errors.subject = 'Subject is required';
            } else if (values.subject.length < 5) {
              errors.subject = 'Subject should be of atleast 5 characters';
            }
            return errors;
          }}
          onSubmit={(values, { setErrors }) => {
            console.log('values', values);
            if (!validateOnSubmit(values, setErrors)) {
              if (!enableBcc) {
                values.bcc = [];
              }
              if (!enableCC) {
                values.cc = [];
              }
              handleEmailSubmit({ ...values, attachment: emailAttachement });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setTouched,
            isSubmitting,
          }) => {
            return (
              <Stack direction={'column'} alignItems={'flex-start'}>
                <Box
                  display="flex"
                  flexDirection={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                  width="100%"
                >
                  <Box>
                    <Button size="small" onClick={toggleCC}>
                      Add Cc
                    </Button>
                    <Button size="small" onClick={toggleBcc}>
                      Add Bcc
                    </Button>
                  </Box>
                </Box>

                <FormControl
                  fullWidth
                  error={touched.recipients && Boolean(errors.recipients)}
                  margin="dense"
                >
                  <Autocompelete
                    options={recipientOptions}
                    label="To"
                    placeholder="Recipient"
                    name="recipients"
                    onChange={(value: any) => {
                      const inputValues = value.map((v: any) => {
                        if (isObject(v)) {
                          // @ts-ignore
                          return v.value.trim();
                        } else {
                          return v.trim();
                        }
                      });
                      setFieldValue('recipients', inputValues);
                    }}
                    onBlur={handleBlur}
                    error={touched.recipients && Boolean(errors.recipients)}
                  />

                  {touched.recipients && Boolean(errors.recipients) && (
                    <FormHelperText error>{errors.recipients}</FormHelperText>
                  )}
                </FormControl>

                {enableCC && (
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={touched.cc && Boolean(errors.cc)}
                  >
                    <Autocompelete
                      options={recipientOptions}
                      label="CC"
                      placeholder="CC"
                      name="cc"
                      onChange={(value: any) => {
                        const inputValues = value.map((v: any) => {
                          if (isObject(v)) {
                            // @ts-ignore
                            return v.value.trim();
                          } else {
                            return v.trim();
                          }
                        });
                        setFieldValue('cc', inputValues);
                      }}
                      onBlur={handleBlur}
                      error={touched.cc && Boolean(errors.cc)}
                    />
                    {touched.cc && Boolean(errors.cc) && (
                      <FormHelperText error>{errors.cc}</FormHelperText>
                    )}
                  </FormControl>
                )}

                {enableBcc && (
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={touched.bcc && Boolean(errors.bcc)}
                  >
                    <Autocompelete
                      options={recipientOptions}
                      label="BCC"
                      placeholder="BCC"
                      name="bcc"
                      onChange={(value: any) => {
                        const inputValues = value.map((v: any) => {
                          if (isObject(v)) {
                            // @ts-ignore
                            return v.value.trim();
                          } else {
                            return v.trim();
                          }
                        });
                        setFieldValue('bcc', inputValues);
                      }}
                      onBlur={handleBlur}
                      error={touched.bcc && Boolean(errors.bcc)}
                    />
                    {touched.bcc && Boolean(errors.bcc) && (
                      <FormHelperText error>{errors.bcc}</FormHelperText>
                    )}
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <TextField
                    margin="dense"
                    label="Subject"
                    name="subject"
                    size="small"
                    required
                    placeholder="Subject"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject?.toString()}
                  />
                </FormControl>
                {templates?.data.length > 0 && (
                  <FormControl fullWidth margin="dense">
                    <MUIAutocomplete
                      options={templates?.data}
                      multiple={false}
                      value={selectedTemplate}
                      getOptionLabel={(option: any) => option.title}
                      renderOption={(props: any, option: any) => (
                        <Box component="li" {...props}>
                          <Stack direction="column">
                            <Typography>{option.title}</Typography>
                            <Typography variant="caption">
                              {`${option?.user?.name} ${dayjs(
                                option.createdAt
                              ).fromNow()}`}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      filterOptions={(options: any, state: any) => {
                        const displayOptions = options.filter((option: any) =>
                          option.title
                            .toLowerCase()
                            .trim()
                            .includes(state.inputValue.toLowerCase().trim())
                        );

                        return displayOptions;
                      }}
                      onChange={(e, value) => {
                        setFieldValue('message', value.content);
                        handleChangeSearchText(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Email Templates"
                          size="small"
                          placeholder="Type to search email templates"
                        />
                      )}
                    />
                  </FormControl>
                )}
                <FormControl error={!!errors.message} fullWidth>
                  <EmailBodyEditor
                    value={values.message}
                    apiKey="03da2acumrl10xnzxdvuqiqxhdhlfrw7q846nw0fbh3k4tvs"
                    onChange={(data: any) => setFieldValue('message', data)}
                  />
                  {errors.message && errors.message && (
                    <FormHelperText error>
                      {errors.message.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <Stack
                  direction="row"
                  spacing={1}
                  py={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle2"
                    marginTop={2}
                    marginBottom={1}
                  >
                    Attachments
                  </Typography>
                  <Button
                    component="label"
                    role={undefined}
                    size="small"
                    tabIndex={-1}
                    startIcon={<AttachFileIcon />}
                  >
                    Add Attachement
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Stack>
                <Box
                  width={'97%'}
                  fontSize={'12px'}
                  dangerouslySetInnerHTML={{ __html: feedbackTable }}
                />
                {emailAttachement && emailAttachement.length > 0 && (
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    width="100%"
                    pt={1}
                    gap={1}
                    flexWrap="wrap"
                  >
                    {emailAttachement.map(
                      ({ email, name }: { email: string; name: string }) => (
                        <Chip
                          icon={<FilePresentOutlinedIcon />}
                          label={email || name}
                          variant="filled"
                          color="primary"
                          size="small"
                          key={email}
                          sx={{
                            '.MuiChip-label': {
                              textTransform: 'lowercase',
                            },
                          }}
                        />
                      )
                    )}
                  </Stack>
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={1}
                >
                  {/* @ts-ignore */}
                  <LoadingButton
                    type="submit"
                    sx={{
                      marginTop: 2,
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                    loading={loading === 'pending'}
                  >
                    Send
                  </LoadingButton>
                  <TemplatePopover template={values.message} />
                </Stack>
              </Stack>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default EmailComposer;
