import { axiosInstance } from '../../utils/axios.instance';

export const fetchHiringWorkflow = (jobId: number | null) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/hiring-workflow?job=${jobId}`,
  });
};

export const fetchHiringWorkflowCandidates = (jobId: number | null) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/hiring-workflow/candidates?job=${jobId}`,
  });
};

export const fetchHiringWorkflowCategory = () => {
  return axiosInstance({
    method: 'GET',
    url: '/api/v1/hiring-workflow/category',
  });
};

export const createHiringWorkflowStage = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/hiring-workflow/create',
    data,
  });
};

export const updateHiringWorkflowStage = (data: any) => {
  return axiosInstance({
    method: 'PUT',
    url: '/api/v1/hiring-workflow/update',
    data,
  });
};

export const deleteHiringWorkflowStage = (data: { id: number }) => {
  return axiosInstance({
    method: 'DELETE',
    url: '/api/v1/job-application/hiring-stage',
    data,
  });
};

export const updateHiringWorkflowSequence = (data: {
  id: number;
  sequenceNo: number;
}) => {
  return axiosInstance({
    method: 'PUT',
    url: '/api/v1/hiring-workflow/update-sequence',
    data,
  });
};

export const taskList = (applicationId: number) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/hiring-workflow/tasks/${applicationId}`,
  });
};

export const allRecruiterTasks = () => {
  return axiosInstance({
    method: 'GET',
    url: '/api/v1/hiring-workflow/tasks',
  });
};

export const addTaskLog = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/hiring-workflow/task-log',
    data,
  });
};

export const applicationTimeline = (applicationId: number) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/hiring-workflow/task-log/${applicationId}`,
  });
};

export const taskShareProfile = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/hiring-workflow/task-log/share-profile',
    data,
  });
};
