import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getRegister, IRegisterPayload } from '../../services/register-user';
import { FRONTEND_URL } from '../../../utils/constants';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';
import { logout } from './loginSlice';

export interface IRegister {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const initialState: IRegister = {
  loading: 'idle',
  error: '',
};

export const registerUser = createAsyncThunk(
  'onboarding/register',
  async (payload: IRegisterPayload, thunkAPI) => {
    try {
      const response = await getRegister(payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = '';
      state.loading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        localStorage.setItem('authToken', action.payload.token);
        localStorage.setItem('email-confirmed', action.payload.isVerified);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('profile', JSON.stringify(action.payload.user));
        setAuthorizationToken(action.payload.token);
        const workspace = action.payload.workspace;
        localStorage.setItem('baseChannel', workspace.slug);
        window.location.href = FRONTEND_URL;
      })
      .addCase(registerUser.rejected, (state, action: any) => {
        state.loading = 'failed';
        state.error = action.payload || 'Some error has occurred';
      })
      .addCase(logout, (state) => {
        state.loading = 'idle';
        state.error = '';
      });
  },
});

export const { resetError } = registerSlice.actions;

export default registerSlice.reducer;
