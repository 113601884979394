import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

import { getlogin, ILoginPayload } from '../../services/login-user';
import { cookie_config } from '../../../utils/constants';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';

const cookies = new Cookies();
export interface ILogin {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const initialState: ILogin = {
  loading: 'idle',
  error: '',
};

export const loginUser = createAsyncThunk(
  'onboarding/login',
  async (payload: ILoginPayload, thunkAPI) => {
    try {
      const response = await getlogin(payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAction('LOGOUT');

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = '';
      state.loading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        localStorage.setItem('authToken', action.payload.token);
        localStorage.setItem('refreshToken', action.payload.refreshToken);
        localStorage.setItem('email-confirmed', action.payload.isVerified);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('profile', JSON.stringify(action.payload.user));
        setAuthorizationToken(action.payload.token);
        localStorage.setItem('baseChannel', action.payload.workspace.slug);
        state.loading = 'succeeded';
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.loading = 'failed';
        state.error = action.payload || 'Some error has occurred';
      })
      .addCase(logout, (state) => {
        state.error = '';
        state.loading = 'idle';
        localStorage.clear();
        setAuthorizationToken(false);
        cookies.remove('authToken', cookie_config);
        cookies.remove('user', cookie_config);
        cookies.remove('email-confirmed', cookie_config);
        cookies.remove('workspace', cookie_config);
      });
  },
});

export const { resetError } = loginSlice.actions;

export default loginSlice.reducer;
