import React from 'react';

import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  height: '100%',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  boxShadow: 24,
  p: 1,
};

interface Props {
  onClose: any;
  client: any;
}

const ChannelInfo = (props: Props) => {
  const { onClose, client } = props;
  const {
    name = '',
    companyAddress = '',
    website = '',
    description = '',
    spocs = [],
  } = client;
  return (
    <Box sx={style}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={2}
      >
        <Typography variant="body1" fontWeight="bold">
          {name}
        </Typography>
        <Tooltip title="close">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box p={2}>
        <Typography variant="body2">{description}</Typography>

        <Stack direction="row" gap={2} alignItems="center" mt={2}>
          <BusinessIcon />{' '}
          <Typography variant="body1" fontWeight="bold">
            Address
          </Typography>
        </Stack>
        <Typography variant="body2">{companyAddress}</Typography>
        <Stack direction="row" gap={2} alignItems="center" mt={2}>
          <LanguageOutlinedIcon />{' '}
          <Typography variant="body1" fontWeight="bold">
            Website
          </Typography>
        </Stack>
        <Typography variant="body2">{website}</Typography>
        <Stack direction="row" gap={2} alignItems="center" my={2}>
          <GroupOutlinedIcon />{' '}
          <Typography variant="body1" fontWeight="bold">
            SPOC(s)
          </Typography>
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {spocs.map(
                (spoc: {
                  name: string;
                  id: number;
                  email: string;
                  phone: string;
                  location: string;
                }) => {
                  return (
                    <TableRow key={`client-spoc-${spoc.id}`}>
                      <TableCell>
                        {spoc.name}
                        <Tooltip title="copy">
                          <IconButton>
                            <ContentCopyOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{spoc.email}</TableCell>
                      <TableCell>{spoc.phone}</TableCell>
                      <TableCell>{spoc.location}</TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ChannelInfo;
