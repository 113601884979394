import { IEvent } from '../../types/calendar';
import { axiosInstance } from '../../utils/axios.instance';

export default class CalendarAPI {
  static async getToken(code: string) {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/google-auth-redirect',
      params: {
        code,
      },
    });
  }

  static listEvents() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/events',
    });
  }

  static upComingEvents() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/upcoming-events',
    });
  }

  static listTodayEvents() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/today-events',
    });
  }

  static addEvent(event: IEvent) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/calendar/event',
      data: event,
    });
  }

  static updateEvent(event: IEvent) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/calendar/event',
      data: event,
    });
  }

  static deleteEvent(id: number, googleEventId?: string) {
    return axiosInstance({
      method: 'DELETE',
      url: '/api/v1/calendar/event',
      params: {
        id,
        googleEventId,
      },
    });
  }

  static getConnectedAccount() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/connected-calendar',
    });
  }

  static deleteConnectedAccount() {
    return axiosInstance({
      method: 'DELETE',
      url: '/api/v1/calendar/connected-calendar',
    });
  }

  static syncGoogleEvents() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/calendar/sync-google-calendar',
    });
  }
}
