import dashboard from './dashboard';
import postings from './postings';
import settings from './settings';
import canidateNavItem from './candidates';

const menuItems = {
  items: [dashboard, postings, canidateNavItem, settings],
};

export default menuItems;
