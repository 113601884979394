import queryString from 'query-string';

import { axiosInstance } from '../../utils/axios.instance';

export const fetchCandidates = (filters: any) => {
  const query = queryString.stringify(filters, {
    skipEmptyString: true,
    skipNull: true,
  });
  return axiosInstance.get(`/api/v2/job-application/candidates?${query}`);
};

export const fetchCandidatesByPostings = (jobId: number, filters?: any) => {
  const query = queryString.stringify(filters, {
    skipEmptyString: true,
    skipNull: true,
  });

  return axiosInstance({
    method: 'GET',
    url: `/api/v2/job-application/${jobId}?${query}`,
  });
};

export const updateCandidateStage = (candidateId: number, stageId: number) =>
  axiosInstance({
    method: 'POST',
    url: '/api/v1/job-application/update-stage',
    data: {
      candidateId,
      stageId,
    },
  });
