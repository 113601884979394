import { axiosInstance } from '../../utils/axios.instance';

export const workspaceOverviewAPI = (workspaceId: number, timeRange?: string) =>
  axiosInstance.get(
    `/api/v1/dashboard/overview/${workspaceId}?timeRange=${timeRange}`
  );

export const fetchAnalytics = (clientId: number) =>
  axiosInstance.get(`/api/v1/dashboard/analytics/${clientId}`);

export const fetchDashboardChart = (workspaceId: number, clientId: number) =>
  axiosInstance.get(`/api/v1/dashboard/charts/${workspaceId}/${clientId}`);

export const fetchRecruiterApplications = (clientId: number) =>
  axiosInstance.get(
    `/api/v1/dashboard/recruiters-uploaded-applications/${clientId}`
  );

export const fetchRecruiterdailyOverview = (clientId: number) =>
  axiosInstance.get(`/api/v1/dashboard/recruiters-daily-overview/${clientId}`);

export const fetchRecruiterOverview = (recruiterId: number) =>
  axiosInstance.get(`/api/v1/dashboard/recruiters-overview/${recruiterId}`);

export const fetchSummary = (time = '1y') =>
  axiosInstance.get(`/api/v1/dashboard/work-summary?time=${time}`);

export const fetchDailySummary = (cientId: number, time: string) =>
  axiosInstance.get(`/api/v1/dashboard/daily-summary/${cientId}?time=${time}`);
