import { useState, SyntheticEvent } from 'react';
import {
  Tab,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import InvitedUser from './InvitedUser.react';
import WorkspaceInvitations from './workspace-invitation.react';

export default () => {
  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ m: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                justifyContent="space-between"
                paddingY={2}
              >
                <Grid item>
                  <Typography variant="h6">Workspace Team</Typography>
                </Grid>
                <Grid item>
                  <IconButton color="primary" href="#/settings/invite">
                    <PersonAddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="workspace user">
                <Tab label="Workspace users" value="1" />
                <Tab label="All Invited users" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <InvitedUser />
            </TabPanel>
            <TabPanel value="2">
              <WorkspaceInvitations />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </Box>
  );
};
