import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import useLocalStorage from '../../../../hooks/use-localstorage';

import menuItems from '../data';

const MenuList = () => {
  const [user] = useLocalStorage('user', null);

  const email = user?.email || '';

  const domain = email.substring(email.lastIndexOf('@') + 1);
  const navItems = menuItems.items.map((item: any) => {
    if (item.type == 'group') {
      if (item.access == 'internal') {
        if (domain == 'savannahr.com') {
          return <NavGroup key={item.id} item={item} />;
        } else {
          return <></>;
        }
      } else {
        return <NavGroup key={item.id} item={item} />;
      }
    }
    return (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Menu Items Error
      </Typography>
    );
  });
  return <>{navItems}</>;
};

export default MenuList;
