import React, { FC, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface EmailBodyEditorProps {
  apiKey: string;
  value: string;
  defaultValue?: string;
  onChange: any;
}

const EmailBodyEditor: FC<EmailBodyEditorProps> = ({
  apiKey,
  defaultValue = '',
  value = '',
  onChange,
}) => {
  const editorRef = useRef(null);

  const handleEditorChange = (data: any) => {
    console.log('data', data);
  };

  return (
    <Editor
      apiKey={apiKey}
      onInit={(evt, editor) => {
        //@ts-ignore
        editorRef.current = editor;
      }}
      onEditorChange={onChange}
      value={value}
      initialValue={defaultValue}
      init={{
        height: 300,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } p {margin: 0}',
      }}
    />
  );
};

export default EmailBodyEditor;
