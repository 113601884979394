import Cookies from 'universal-cookie';
const cookies: any = new Cookies();

export default class AuthService {
  constructor() {
    this.getProfile = this.getProfile.bind(this);
  }

  setProfile(profile: any) {
    // Saves profile data to localStorage
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  getProfile() {
    // Retrieves the profile data from localStorage
    const profile = localStorage.getItem('user');
    return profile ? JSON.parse(profile) : {};
  }

  setToken(token: string) {
    // Saves user token to localStorage and Cookies
    localStorage.setItem('authToken', token);
    cookies.set('authToken', token, { path: '/', domain: '.hirexl.in' });
  }

  getToken() {
    // Retrieves the user token from cookies
    return localStorage.getItem('authToken');
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token;
  }

  logout() {
    // Clear user token and profile data from localStorage
    // cookies.removeItem("authToken", {
    //   path: "/",
    //   domain: ".hirexl.in",
    // });
    localStorage.removeItem('authToken');
    localStorage.removeItem('profile');
  }
}
