import Cookies from 'universal-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchSession } from '../../services/fetch-session';
import { logout } from '../onboarding/loginSlice';

const cookies = new Cookies();

const authToken = cookies.get('authToken');
export interface IAppState {
  isEmailVerified: boolean;
  token: string;
  permissions: any;
  user: any;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  workspace: Array<any>;
  otherWorkspaces: Array<any>;
  channels: Array<any>;
  email?: string;
}

const initialState: IAppState = {
  isEmailVerified: false,
  token: authToken || '',
  permissions: {},
  user: {},
  loading: 'idle',
  error: '',
  workspace: [],
  otherWorkspaces: [],
  channels: [],
};

export const getSession = createAsyncThunk('user/session', async () => {
  const baseChannel: any | string = localStorage.getItem('baseChannel');
  const response = await fetchSession(baseChannel);
  return response.data;
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    checkUserEmail: (state, action) => {
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    },
    updateUserInfo: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setPermissions: (state, action) => {
      const userPermissions: any = {};
      action.payload.forEach(({ v2 }: any) => {
        userPermissions[v2] = true;
      });
      return {
        ...state,
        permissions: userPermissions,
      };
    },
    addToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSession.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.workspace = action.payload.workspace;
        state.otherWorkspaces = action.payload.otherWorkspaces;
        state.channels = action.payload.channels;
        const permissions: any = {};
        action.payload.permissions.forEach(({ v2 }: any) => {
          const accessName = v2.split(':')[0];
          return (permissions[accessName] = true);
        });

        state.permissions = permissions;
      })
      .addCase(getSession.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Something went wrong!';
      })
      .addCase(logout, (state) => {
        state.isEmailVerified = false;
        state.token = '';
        state.permissions = {};
        state.user = {};
        state.loading = 'idle';
        state.error = '';
        state.workspace = [];
        state.otherWorkspaces = [];
        state.channels = [];
      });
  },
});

export const { addToken, checkUserEmail, updateUserInfo, setPermissions } =
  appSlice.actions;

export default appSlice.reducer;
