import { axiosInstance } from '../../utils/axios.instance';

export default class FileServiceAPI {
  static async uploadFileToS3(payload: FormData) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/object-storage-toolkit/upload',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: payload,
    });
  }
}
