import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Stack, Typography, Grid } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { useAppSelector } from '../../hooks/redux';

import InviteForm from './InviteForm.react';

const Invite = () => {
  const navigate = useNavigate();
  const { permissions, workspace } = useAppSelector((state) => state.app);
  const { clients } = useAppSelector((state) => state.client);

  useEffect(() => {
    const { invt_member } = permissions;
    if (!invt_member) {
      navigate('/settings');
    }
  }, []);

  return (
    <Card sx={{ m: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <PersonAddAlt1Icon fontSize="inherit" />
          <Typography variant="h4">Invite member to your workspace</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={6}>
            <InviteForm clients={clients} workspace={workspace} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Invite;
