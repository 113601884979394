import { useState, useRef, useEffect, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Divider,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import Transitions from '../../../../theme/extended-components/Transitions';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import withAuth from '../../../../utils/withAuth';

const ProfileSection = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const { isLoading, auth }: any = props;
  const user = auth.getProfile();
  const { workspace: { slug = '' } = {}, otherWorkspaces = [] }: any =
    useAppSelector((state) => state.app);

  /**
   * anchorRef is used on different component and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleClose = (event: any) => {
    //@ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (
    event: MouseEvent<HTMLDivElement>,
    index: number,
    route = ''
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      //@ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleWorkspaceSwitch = (workspace: any) => {
    localStorage.setItem('baseChannel', workspace.slug);
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <Avatar
        sx={{
          ...theme.typography.body2,
          margin: '8px 0 8px 8px !important',
          cursor: 'pointer',
          bgcolor: theme.palette.primary.dark,
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {user?.name ? user?.name[0] : ''}
      </Avatar>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{ p: 1, cursor: 'pointer' }}
                      component="div"
                      onClick={(e) => handleListItemClick(e, 3, 'dashboard')}
                    >
                      <Typography variant="subtitle2">{user?.name}</Typography>
                      <Typography variant="body2" textTransform="uppercase">
                        {slug}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden',
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%',
                            },
                            '& .MuiListItemButton-root': {
                              mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{
                              borderRadius: '14px',
                            }}
                            disabled
                            selected={selectedIndex === 0}
                            onClick={(event) =>
                              handleListItemClick(event, 0, '')
                            }
                          >
                            <ListItemIcon>
                              <SettingsIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Account Settings
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          {/* <ListItemButton
                            sx={{
                              borderRadius: '14px',
                            }}
                            selected={selectedIndex === 1}
                            onClick={(event) =>
                              handleListItemClick(event, 1, '')
                            }
                          >
                            <ListItemIcon>
                              <PersonIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="body2">
                                      Social Profile
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Chip
                                      label="02"
                                      size="small"
                                      sx={{
                                        bgcolor: theme.palette.warning.dark,
                                        color: theme.palette.background.default,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton> */}
                          <ListItemButton
                            sx={{
                              borderRadius: '14px',
                            }}
                            selected={selectedIndex === 4}
                            href="/#/logout"
                          >
                            <ListItemIcon>
                              <LogoutIcon fontSize="inherit" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </Box>
                    {otherWorkspaces.length > 0 && (
                      <>
                        <Divider />
                        <Box
                          style={{
                            height: '100%',
                            maxHeight: 'calc(100vh - 250px)',
                            overflowX: 'hidden',
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <List
                              component="nav"
                              sx={{
                                width: '100%',
                                maxWidth: 350,
                                minWidth: 300,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                  minWidth: '100%',
                                },
                                '& .MuiListItemButton-root': {
                                  mt: 0.5,
                                },
                              }}
                            >
                              {otherWorkspaces.map((workspace: any) => (
                                <ListItemButton
                                  key={`workspace-${workspace.name}`}
                                  sx={{
                                    borderRadius: '14px',
                                  }}
                                  selected={selectedIndex === 0}
                                  onClick={(event) =>
                                    handleWorkspaceSwitch(workspace)
                                  }
                                >
                                  <ListItemIcon>
                                    <CorporateFareIcon fontSize="inherit" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="body2"
                                        textTransform="uppercase"
                                      >
                                        {workspace.name}
                                      </Typography>
                                    }
                                  />
                                </ListItemButton>
                              ))}
                            </List>
                          </Box>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default withAuth(ProfileSection);
