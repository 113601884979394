import { Alert, AlertTitle } from '@mui/material';

import { useAppSelector } from '../../hooks/redux';

const AlertContainer = () => {
  const {
    title = '',
    message = '',
    severity = 'info',
  } = useAppSelector((state) => state.alert);

  return (
    message && (
      <Alert severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    )
  );
};

export default AlertContainer;
