import { axiosInstance } from '../../utils/axios.instance';

export default class JobAPI {
  static async stakeholder(jobId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/job/stakeholder/${jobId}`,
    });
  }

  static async updateJobStatus(jobId: number, status: string) {
    return axiosInstance({
      method: 'PATCH',
      url: `/api/v1/job/status/${jobId}`,
      data: {
        status,
      },
    });
  }

  static async addStakeholdersToJob(
    payload: Array<{ recruiterId: number; jobId: number }>
  ) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/job/add-stakeholder',
      data: payload,
    });
  }

  static async addStakeholdersToApplication(
    payload: Array<{ recruiterId: number; applicationId: number }>
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/assign-recruiter',
      data: payload,
    });
  }

  static async fetchAssignedJobs() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/job/recruiter-active-jobs',
    });
  }
}
