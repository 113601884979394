import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchJobs } from '../../services/fetch-jobs';
import { fetchJobById, sendJobDescriptionEmail } from '../../services/jobs';
import { RootState } from '../../store';
import JobAPI from '../../services/job.service';

export interface IJobState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  list: Array<{}>;
  total: number;
  jobPosting: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
    data: any;
  };
  sendJD: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
    data: any;
  };
  updateStakeholder: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
  };
}

const initialState: IJobState = {
  loading: 'idle',
  error: '',
  list: [],
  total: 0,
  jobPosting: {
    loading: 'idle',
    error: '',
    data: {},
  },
  sendJD: {
    loading: 'idle',
    error: '',
    data: {},
  },
  updateStakeholder: {
    loading: 'idle',
    error: '',
  },
};

export const getJobsByPage = createAsyncThunk(
  'jobs/fetchJobs',
  async (
    {
      page,
      status,
      selfPosted,
    }: { page: number; status?: string; selfPosted?: boolean },
    { getState }
  ) => {
    const state = getState() as RootState;
    const clientId = state.client?.selectedClient?.id || null;
    //@ts-ignore
    const workspaceId = state.app.workspace.id;
    const response = await fetchJobs(
      page,
      workspaceId,
      clientId,
      status,
      selfPosted
    );
    return response.data;
  }
);

export const getJobById = createAsyncThunk(
  'job/fetchJobById',
  async (jobId: number) => {
    const response = await fetchJobById(jobId);
    return response.data;
  }
);

export const sendJobDescription = createAsyncThunk(
  'job/sendJobDescription',
  async (data: any) => {
    const response = await sendJobDescriptionEmail(data);
    return response.data;
  }
);

export const addStakeholder = createAsyncThunk(
  'job/updateStakeholder',
  async (
    stakeholder: Array<{ recruiterId: number; jobId: number }>,
    { dispatch }
  ) => {
    const response = await JobAPI.addStakeholdersToJob(stakeholder);
    dispatch(getJobById(stakeholder[0].jobId));
    return response;
  }
);

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    resetJob: (state) => {
      state.jobPosting.data = {};
    },
    resetSendJD: (state) => {
      state.sendJD.loading = 'idle';
      state.sendJD.data = {};
      state.sendJD.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobsByPage.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(getJobsByPage.fulfilled, (state, action) => {
        return {
          ...state,
          loading: 'succeeded',
          list: action.payload.jobs,
          total: action.payload.total,
        };
      })
      .addCase(getJobsByPage.rejected, (state, action) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(getJobById.pending, (state) => {
        state.jobPosting.loading = 'pending';
      })
      .addCase(getJobById.fulfilled, (state, action) => {
        state.jobPosting.data = action.payload;
        state.jobPosting.loading = 'succeeded';
      })
      .addCase(getJobById.rejected, (state) => {
        state.jobPosting.loading = 'failed';
        state.jobPosting.error = 'Something went wrong!';
      })
      .addCase(sendJobDescription.pending, (state) => {
        state.sendJD.loading = 'pending';
      })
      .addCase(sendJobDescription.fulfilled, (state, action) => {
        state.sendJD.data = action.payload;
        state.sendJD.loading = 'succeeded';
      })
      .addCase(sendJobDescription.rejected, (state) => {
        state.sendJD.loading = 'failed';
        state.sendJD.error = 'Something went wrong!';
      })
      .addCase(addStakeholder.pending, (state) => {
        state.updateStakeholder.loading = 'pending';
      })
      .addCase(addStakeholder.fulfilled, (state, action) => {
        state.updateStakeholder.loading = 'succeeded';
      })
      .addCase(addStakeholder.rejected, (state) => {
        state.updateStakeholder.loading = 'failed';
        state.updateStakeholder.error = 'Something went wrong!';
      });
  },
});

export const { resetJob, resetSendJD } = jobSlice.actions;

export default jobSlice.reducer;
