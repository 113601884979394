import { useState, useMemo, useCallback, useRef } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Collapse,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { ROOT_URL } from '../../utils/constants';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

type IExpandProps = IconButtonProps & {
  expand: boolean;
  color: 'primary' | 'secondary';
  onClick: Function;
  'aria-expanded': boolean;
  'aria-label': string;
};

const ExpandMore = styled((props: IExpandProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  cvUrl: string | null;
  token: string;
  onChangeCV?: any;
}

const Resume = (props: Props) => {
  const [expanded, setExpanded] = useState(true);
  const [numPages, setNumPages] = useState<number>();
  const fileInputRef = useRef(null);

  let { cvUrl, onChangeCV } = props;
  const authToken = localStorage.getItem('authToken');

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    []
  );

  const onDocumentLoadError = useCallback((err: any) => {
    console.error('Error loading document:', err);
  }, []);

  const memoizedOptions = useMemo(
    () => ({
      httpHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    }),
    [authToken]
  );

  if (!cvUrl) {
    return (
      <Card>
        <CardContent>No Resume has been found for this candidate!</CardContent>
      </Card>
    );
  }

  const handleUploadFileClick = () => {
    //@ts-ignore
    fileInputRef?.current.click();
  };

  return (
    <Card>
      <CardHeader
        title="Resume"
        sx={{
          pb: 1,
        }}
        subheader="Candidate uploaded resume document"
        action={
          <>
            <input
              type="file"
              ref={fileInputRef}
              accept=".doc,.docx,.pdf"
              style={{ display: 'none' }}
              onChange={onChangeCV}
            />
            <Tooltip title="Change Resume">
              <IconButton color="primary" onClick={handleUploadFileClick}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Resume">
              <IconButton
                color="primary"
                LinkComponent={'a'}
                href={cvUrl}
                target="_blank"
              >
                <DownloadIcon color="primary" />
              </IconButton>
            </Tooltip>
            <ExpandMore
              expand={expanded}
              color="primary"
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Document
            file={`${ROOT_URL}/api/v1/job-application/view?file=${cvUrl}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            options={memoizedOptions}
            error="Failed to load file"
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  renderTextLayer={false}
                  pageNumber={page}
                  renderAnnotationLayer={false}
                  key={`page-${page}`}
                />
              ))}
          </Document>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Resume;
