import React, { useCallback, useState, useEffect } from 'react';
import { Stack, Tooltip, Avatar, Button, Box } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { stringAvatar, stringToColor } from '../../../utils/helper-functions';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './input.style.scss';

interface Props {
  mode: 'EDIT' | 'REPLY' | 'COMMENT';
  user: {
    name: string;
    id: number;
    profilePicUrl?: string;
  };
  isSubmitting?: boolean;
  commentText?: string;
  onCancel?: Function;
  onSubmit: Function;
  commentParentId?: number | null;
  commentId?: number | null;
}

const RichTextInput = (props: Props) => {
  const {
    user,
    mode = 'COMMENT',
    isSubmitting = false,
    onSubmit,
    commentId,
    commentParentId,
  } = props;

  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [editText, setEditText] = useState<string>('');

  const onEditorStateChange: Function = (editorState: any) => {
    setEditor(editorState);
  };
  useEffect(() => {
    setEditText(
      draftToHtml(convertToRaw(editorState.getCurrentContent())).trim()
    );
  }, [editorState]);

  useEffect(() => {
    if (!isSubmitting && mode == 'COMMENT') {
      setEditor(EditorState.createEmpty());
    }
  }, [isSubmitting, mode]);

  console.log('editText', editText);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log('editText-->', editText);
      if (editText != '<p></p>')
        onSubmit(mode, editText, commentId, commentParentId);
    },
    [editText]
  );

  return (
    <Stack
      component="form"
      direction="row"
      alignItems="flex-start"
      gap={1}
      width="100%"
      onSubmit={handleSubmit}
    >
      <Tooltip title={user.name}>
        <Avatar
          {...stringAvatar(user.name)}
          sx={{
            width: 30,
            height: 30,
            fontSize: '12px',
            bgcolor: stringToColor(user.name),
          }}
        />
      </Tooltip>
      <Box className="advancedBorder">
        <Editor
          editorState={editorState}
          placeholder={'Type your reply here'}
          onEditorStateChange={(editorState) =>
            onEditorStateChange(editorState)
          }
          toolbar={{
            options: ['inline', 'list', 'colorPicker', 'link', 'emoji'],
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link'],
              linkCallback: undefined,
            },
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
            },
          }}
        />
      </Box>
      {mode != 'COMMENT' && (
        // @ts-ignore
        <Button variant="outlined" size="small" onClick={onCancel}>
          Cancel
        </Button>
      )}
      <Button
        variant="outlined"
        size="small"
        type="submit"
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Stack>
  );
};

export default RichTextInput;
