import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  TableRow,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import _ from 'lodash';
import { ROOT_URL } from '../../utils/constants';

interface Props {
  applicationId: number;
  jobId: number;
  evaluationTemaplate?: any;
  onSubmitFeedback: any;
  feedbacks: Array<any>;
}

export default (props: Props) => {
  const [feedbackValues, setFeedbackFormValues] = useState<any>({});

  useEffect(() => {
    if (props.feedbacks.length) {
      const values: any = {};
      props.feedbacks[0].forEach((feedback: any) => {
        values[feedback.label] = feedback.value;
      });
      setFeedbackFormValues(values);
    } else {
      setFeedbackFormValues({});
    }
  }, [props.feedbacks]);

  const handleChange = (e: any) => {
    setFeedbackFormValues({
      ...feedbackValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    props.onSubmitFeedback(feedbackValues);
    setFeedbackFormValues({});
  };

  const template = props?.evaluationTemaplate?.template || [];

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Recruiter Feedback
        </Typography>
        {template.length > 0 && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap={2}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="subtitle1">Feedbacks</Typography>
              <Tooltip title="Download Feedback">
                <IconButton
                  color="primary"
                  href={`${ROOT_URL}/api/v1/job-applicant-evaluation/download-feedback/${props.applicationId}`}
                >
                  <SimCardDownloadIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TableContainer>
              <Table>
                <TableHead>
                  {template.map((column: string, index: number) => (
                    <TableCell key={`tbl-header-${column}`}>{column}</TableCell>
                  ))}
                  <TableCell key={'tbl-header-remark'}>Remark</TableCell>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {template.map((column: string, index: number) => (
                      <TableCell
                        key={`tbl-header-${column}-value`}
                        style={{ verticalAlign: 'top' }}
                      >
                        {feedbackValues[column] || ''}
                      </TableCell>
                    ))}
                    <TableCell
                      key={'tbl-header-remark'}
                      style={{ verticalAlign: 'top' }}
                    >
                      {feedbackValues['remark'] || ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell colSpan={2}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                      Add your feedback
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  {template.map((column: string, i: number) => (
                    <TableRow key={`feedback-tbl-cell-label-${i}`}>
                      <TableCell variant="head">
                        <Typography>{column}</Typography>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label={column}
                          size="small"
                          name={column}
                          value={feedbackValues[column] || ''}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell variant="head">
                      <Typography>Remark</Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <TextField
                        label="Remark"
                        size="small"
                        multiline
                        value={feedbackValues['remark'] || ''}
                        rows={3}
                        fullWidth
                        name="remark"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
