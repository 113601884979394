import { useTheme } from '@mui/material/styles';
import { Box, Stack, IconButton, Link, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';

import withAuth from '../../../utils/withAuth';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import ClientSection from './ClientSection';
import { drawerWidth } from '../../../utils/constants';

export const Header = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading, auth } = props;
  const isAuthenticate = isLoading ? null : auth.loggedIn();

  const handleBackward = () => {
    navigate(-1);
  };

  const handleForward = () => {
    navigate(1);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <Box
        sx={{
          width: drawerWidth + 20,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Link href="/#/" underline="none">
            <Typography
              variant="h3"
              sx={{
                fontFamily: 'Montserrat',
                color: '#ff6451',
              }}
            >
              HireXL
            </Typography>
          </Link>
        </Box>

        <IconButton onClick={props.handleLeftDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <IconButton color="default" onClick={handleBackward}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton color="default" onClick={handleForward}>
          <ArrowForwardIcon />
        </IconButton>
        <IconButton color="default" onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <ClientSection />
        <NotificationSection />
        {isAuthenticate && <ProfileSection />}
      </Stack>
    </>
  );
};

export default withAuth(Header);
