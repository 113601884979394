import React, { useEffect, useState } from 'react';

import {
  TextField,
  Box,
  FormControl,
  FormLabel,
  Stack,
  Grid,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  handleSubmit: any;
  isSubmitting: boolean;
  setFieldValue: any;
}

const spocFields = [
  { label: 'Name', name: 'name' },
  { label: 'Email', name: 'email' },
  { label: 'Phone', name: 'phone' },
  { label: 'Location', name: 'location' },
];

const SPOCFieldView = ({
  fields,
  sequence,
  removeSpoc,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: {
  fields: Array<{
    name: string;
    label: string;
  }>;
  sequence: number;
  removeSpoc: Function;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
}) => {
  console.log('values', values.spoc);
  return (
    <Grid container spacing={1} mb={2}>
      <Grid
        item
        xs={12}
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">SPOC {sequence + 1}</Typography>
        <IconButton onClick={() => removeSpoc(sequence)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {fields.map((item, index) => (
        <Grid item xs={12} md={6} key={index}>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              label={item.label}
              name={`spoc[${sequence}][${item.name}]`}
              value={
                values.spoc[sequence] ? values.spoc[sequence][item.name] : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.spoc &&
                touched.spoc[sequence]?.[item.name] &&
                Boolean(errors.spoc && errors.spoc[sequence]?.[item.name])
              }
              helperText={
                touched.spoc &&
                touched.spoc[sequence]?.[item.name] &&
                errors.spoc &&
                errors.spoc[sequence]?.[item.name]
              }
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

export const ChannelForm = (props: IProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = props;
  const [filedArray, setFiledArray] = useState<Array<any>>([]);

  const pushSPOCElement = () => {
    setFiledArray([...filedArray, spocFields]);
  };

  const handleRemoveSpoc = (index: number) => {
    const array = [...filedArray];
    array.splice(index, 1);
    setFiledArray(array);
    const updatedSpoc = [...values.spoc];
    updatedSpoc.splice(index, 1);
    setFieldValue('spoc', updatedSpoc);
  };

  useEffect(() => {
    if (values?.spoc.length) {
      setFiledArray(
        Array.from({ length: values?.spoc.length }, () => spocFields)
      );
    }
  }, [values.spoc]);

  return (
    <Box component="form" minWidth={400} onSubmit={handleSubmit}>
      <Stack gap={1} direction="row">
        <FormControl fullWidth>
          <TextField
            label="Company Name"
            placeholder="E.g Technocube India"
            margin="dense"
            size="small"
            fullWidth
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Website"
            placeholder="E.g www.technocube.co"
            margin="dense"
            size="small"
            fullWidth
            name="website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.website && Boolean(errors.website)}
            helperText={touched.website && errors.website}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="LinkeDin URL"
            placeholder="E.g https://www.linkedin.com/company/savanna-hr/"
            margin="dense"
            size="small"
            fullWidth
            name="linkedinURL"
            value={values.linkedinURL}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.linkedinURL && Boolean(errors.linkedinURL)}
            helperText={touched.linkedinURL && errors.linkedinURL}
          />
        </FormControl>
      </Stack>
      <FormControl fullWidth>
        <TextField
          label="Office Address"
          multiline
          rows={2}
          placeholder="Enter Client Office Address"
          margin="dense"
          size="small"
          fullWidth
          name="companyAddress"
          value={values.companyAddress}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.companyAddress && Boolean(errors.companyAddress)}
          helperText={touched.companyAddress && errors.companyAddress}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          label="Description"
          multiline
          rows={5}
          placeholder="Brief about the company"
          margin="dense"
          size="small"
          fullWidth
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
        />
      </FormControl>

      <Box my={2}>
        <FormLabel>Add Company Location wide SPOC(s)</FormLabel>
      </Box>
      {filedArray.map((item, index) => (
        <SPOCFieldView
          fields={item}
          key={index}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          sequence={index}
          removeSpoc={handleRemoveSpoc}
        />
      ))}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
        sx={{ mt: 1 }}
        onClick={pushSPOCElement}
      >
        Add
      </Button>

      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        type="submit"
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  );
};

export default ChannelForm;
