import axios from 'axios';

import { ROOT_URL } from './constants';
import * as api from '../redux/services/login-user';
import setAuthorizationToken from './setAuthorizationToken';

const token = localStorage.getItem('authToken');

export const axiosInstance = axios.create({
  baseURL: ROOT_URL,
  headers: {
    authorization: `bearer ${token}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    const workspace = localStorage.getItem('baseChannel');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (workspace) {
      config.headers['workspace'] = workspace;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired access token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await api.refreshToken();
        localStorage.setItem('authToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + response.data.accessToken;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.clear();
        setAuthorizationToken(false);
        window.location.reload();
      }
    } else {
      return Promise.reject(error);
    }
  }
);
